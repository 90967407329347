/*=====================================================================

	CSS ASSETS
	----------

	Here are all imports of css libraries and helpers like
	Bootstrap, fontAwesome, Slick, .. etc.

 *=================================================================== */


// CSS Assets Imports
// ==================================

@import "https://fonts.googleapis.com/css?family=Poppins:400,600&display=swap";
@import "_variables";
@import "bootstrap";
@import "slick/slick.scss";
@import "../fonts/fontawesome/css/all.css";
@import "https://use.fontawesome.com/releases/v5.3.1/css/v4-shims.css";
@import "magnific-popup";
@import "hamburgers/hamburgers";
@import "bg-patterns";

// ==================================
